import { Modal } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const CaregiverInfoModal = ({ modalVisible, handleClose }) => {
  return (
    <>
      <Modal
        className="info-modal-container bookingConfirmaedModal"
        centered
        width={572}
        header={null}
        footer={null}
        open={modalVisible}
        onOk={handleClose}
        onCancel={handleClose}
        closable
        closeIcon={<img src="/images/icons/close-icon-gray.svg" alt={""} />}
      >
        {/* <div className="image-container">
          <img
            alt={""}
            src="/images/booking-confirm-image.svg"
            className="text-center"
          />
        </div> */}
        <div className="text-center booking-complete-details">
          <h2 style={{ fontWeight: "700" }}>
            Join Our Network of Independent Caregivers
          </h2>
          <p style={{ color: "red", width: "100%", fontWeight: 600 }}>
            Important: MF Homecare is not an agency or a recruiter. We do not
            process resume applications or refer you to open jobs. We are an
            online platform where families can book care services directly with
            you.
          </p>
          <p style={{ width: "100%" }}>
            {/* <b style={{ color: "#000000" }}>Download the App</b> */}
            {/* <br /> */}
            To register, please download our app and follow the directions on
            the screen. Our app is only available on Canadian mobile devices
          </p>
        </div>
        <div
          className="download-app-button"
          style={{ justifyContent: "space-around" }}
        >
          <Link to="https://apps.apple.com/us/app/mf-homecare/id6443815250">
            <p>Download for Apple iOS</p>
            <img alt={""} src="/images/icons/app-store-icon.svg" />
          </Link>
          <Link to="https://play.google.com/store/apps/details?id=com.homecare.seasons&pli=1">
            <p>Download for Android</p>
            <img alt={""} src="/images/icons/play-store-icon.svg" />
          </Link>
        </div>
      </Modal>
    </>
  );
};

export default CaregiverInfoModal;
